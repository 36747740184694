
import Vue from "vue";
import gmapsInit from "@/services/mapService";
import notification from "@/services/notificationService";

export default Vue.extend({
  name: "GeoFenceRightSidebar",
  props: {
    geoFence: Object
  },
  data() {
    return {
      deleteGeoFenceToggle: false,
      map: undefined as undefined | any
    };
  },
  watch: {
    geoFence: function () {
      if (this.geoFence) {
        this.loadMap();
      }
    }
  },
  methods: {
    openDeleteGeoFenceModal() {
      this.deleteGeoFenceToggle = true;
    },
    deleteGeoFence() {
      this.$store.dispatch("deleteGeoFence", this.$route.query.id).then(() => {
        this.$store.dispatch("getGeoFences");
        this.deleteGeoFenceToggle = false;
      });
    },
    async loadMap() {
      try {
        const google: any = await gmapsInit();
        const geocoder = new google.maps.Geocoder();
        this.map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            center: {
              lat: this.geoFence.sourceLatitude,
              lng: this.geoFence.sourceLongitude
            },
            zoom: 15,
            mapTypeControl: false
          }
        );
        // Add circle on map
        const regionCirlce = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map: this.map,
          center: new google.maps.LatLng(
            this.geoFence.sourceLatitude,
            this.geoFence.sourceLongitude
          ),
          radius: this.geoFence.radius,
          editable: false
        });
        // Add marker on center of circle
        const centerMarket = new google.maps.Marker({
          position: {
            lat: this.geoFence.sourceLatitude,
            lng: this.geoFence.sourceLongitude
          },
          map: this.map
        });
        const bounds = new google.maps.LatLngBounds();
        bounds.union(regionCirlce.getBounds());
        this.map.fitBounds(bounds);
      } catch (err) {
        notification.error(err.message);
      }
    }
  }
});
