
import Vue from "vue";
import notification from "@/services/notificationService";
import router from "@/router";
import lodash from "lodash";
import { GeoFence } from "@/shared/models";
import GeoFenceRightSidebar from "@/components/GeoFenceRightSidebar.vue";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
export default Vue.extend({
  name: "GeoFence",
  components: { GeoFenceRightSidebar, NoTenantSelectedMessage },
  data() {
    return {
      tableProps: {
        isPaginated: true,
        isPaginationSimple: false,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 10
      },

      selected: undefined as undefined | GeoFence,
      filteredGeoFences: undefined as undefined | GeoFence[],

      filters: {
        searchQuery: undefined as undefined | string,
        sort: "descending"
      }
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    geoFences() {
      return this.$store.state.geoFences;
    }
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
      this.selected = undefined;
    },
    geoFences: function () {
      this.filterGeoFences();
      if (this.$route.query.id) {
        const selectedGeoFence = lodash.find(this.geoFences, {
          id: this.$route.query.id
        } as GeoFence);
        // To do: Use of any to be changed on lodash update
        this.selected = selectedGeoFence;
      }
    }
  },
  created() {
    this.initializeView();
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        if (this.$route.query.sort) {
          this.filters.sort = this.$route.query.sort.toString();
        }
        if (this.$route.query.search) {
          this.filters.searchQuery = this.$route.query.search.toString();
        }
        this.getGeoFences();
      }
    },
    filterGeoFences() {
      // adding selected filters as query
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sort: this.filters.sort,
          search: this.filters.searchQuery
        }
      });

      // load GeoFences to filter
      this.filteredGeoFences = this.geoFences;

      // check if search query
      if (this.filters.searchQuery) {
        this.filteredGeoFences = this.filteredGeoFences?.filter((geoFence) => {
          // todo -
          return geoFence.name
            ?.toLowerCase()
            .includes(this.filters.searchQuery!.toLowerCase());
        });
      }
      // If a sorting method is selected
      if (this.filters.sort) {
        if (this.filters.sort === "ascending") {
          this.filteredGeoFences = lodash.orderBy(
            this.filteredGeoFences,
            ["createdAt"],
            ["asc"]
          ) as GeoFence[];
        } else {
          this.filteredGeoFences = lodash.orderBy(
            this.filteredGeoFences,
            ["createdAt"],
            ["desc"]
          ) as GeoFence[];
        }
      }
    },
    showDetail(payload: GeoFence) {
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: payload.id
        }
      });
    },
    removeSelection() {
      this.selected = undefined;
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: undefined
        }
      });
    },
    getGeoFences() {
      this.$store.dispatch("getGeoFences", {
        selectedTenant: this.selectedTenant
      });
    }
  }
});
